import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PresentationComponent } from "./pages/presentation/presentation.component";
import { AuthGuard } from "./providers/auth.guard";

const routes: Routes = [
  // {
  //   path: "",
  //   redirectTo: "presentation",
  //   pathMatch: "full"
  // },
  // {
  //   path: "presentation",
  //   component: PresentationComponent
  // },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.DashboardsModule)
      },
      {
        path: "equipments",
        loadChildren: () => import('./pages/equipments/equipments.module').then(m => m.EquipmentsModule)
      },
      {
        path: "roles",
        loadChildren: () => import('./pages/roles/roles.module').then(m => m.RolesModule)
      },
      {
        path: "ticket",
        loadChildren: () => import('./pages/ticket/ticket.module').then(m => m.TicketModule)
      },
      {
        path: "quotation",
        loadChildren: () => import('./pages/quotation/quotation.module').then(m => m.QuotationModule)
      },
      {
        path: "spare-parts",
        loadChildren: () => import('./pages/spare-parts/spare-parts.module').then(m => m.SparePartsModule)
      },
      {
        path: "components",
        loadChildren: () => import('./pages/components/components.module').then(m => m.ComponentsModule)
      },
      {
        path: "customer",
        loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: "contracts",
        loadChildren: () => import('./pages/contracts/contracts.module').then(m => m.ContractsModule)
      },
      {
        path: "service",
        loadChildren: () => import('./pages/service/service.module').then(m => m.ServiceModule)
      },
      {
        path: "invoices",
        loadChildren: () => import('./pages/invoices/invoices.module').then(m => m.InvoicesModule)
      },

      {
        path: "technician",
        loadChildren: () => import('./pages/technician/technician.module').then(m => m.TechnicianModule)
      },
      {
        path: "supervisor",
        loadChildren: () => import('./pages/supervisor/supervisor.module').then(m => m.SupervisorModule)
      },
      {
        path: "system",
        loadChildren: () => import('./pages/system/system.module').then(m => m.SystemModule)
      },
      {
        path: "faq",
        loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: "maintainance-checklist",
        loadChildren: () => import('./pages/maintainance-checklist/maintainance-checklist.module').then(m => m.MaintainanceChecklistModule)
      },
      {
        path: "news-letter",
        loadChildren: () => import('./pages/news-letter/news-letter.module').then(m => m.NewsLetterModule)
      },
      {
        path: "forms",
        loadChildren: () => import('./pages/forms/forms.module').then(m => m.FormsModules)
      },
      {
        path: "tables",
        loadChildren: () => import('./pages/tables/tables.module').then(m => m.TablesModule)
      },
      {
        path: "maps",
        loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule)
      },
      {
        path: "widgets",
        loadChildren: () => import('./pages/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: "charts",
        loadChildren: () => import('./pages/charts/charts.module').then(m => m.ChartsModule)
      },
      {
        path: "examples",
        loadChildren: () => import('./pages/auth/examples.module').then(m => m.ExamplesModule)
      },
      {
        path: "profile",
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: "master",
        loadChildren: () => import('./pages/master/master.module').then(m => m.MasterModule)
      },
      {
        path: "service_schedule",
        loadChildren: () => import('./pages/service_schedule/service_schedule.module').then(m => m.ServiceScheduleModule)
      },
      {
        path: "promotion",
        loadChildren: () => import('./pages/promotion/promotion.module').then(m => m.PromotionModule)
      },
      {
        path: "feedback",
        loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackModule)
      },
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "auth",
        loadChildren:
          () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      }
    ]
  },
  {
    path: "**",
    redirectTo: "dashboard"
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
