import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RouterModule } from "@angular/router";
import { PromotionComponent } from "./promotion.component";
import { PromotionRoutes } from "./promotion.routing";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [PromotionComponent],
  imports: [CommonModule,  
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(), NgxDatatableModule, RouterModule.forChild(PromotionRoutes)]
})
export class PromotionModule {}
