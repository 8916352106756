
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    apiUrl: string = environment.apiUrl;
    // HAS_LOGGED_IN = 'hasLoggedIn';
    // HAS_SEEN_TUTORIAL = 'hasSeenTutorial';

    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    
    constructor(private http: HttpClient,
        public commonService: CommonService,
        public router: Router
        ) { 
            this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
            this.currentUser = this.currentUserSubject.asObservable();
        }
        public get currentUserValue(): any {
            return this.currentUserSubject.value;
        }

    login(model) {
       
        return this.http.post<any>(this.apiUrl + '/auth/login', model).pipe(
            map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.commonService.setCurrentUser(user);
                    localStorage.setItem('token', user.token);
                    this.commonService.apiToken = user.token;
                   
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    generateOTP(model) {
        return this.http.post<any>(this.apiUrl + '/auth/generateOTP', model);
    }

    verifyOTP(model) {
        return this.http.post<any>(this.apiUrl + '/auth/verifyOTP', model);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        //localStorage.clear();
        //sessionStorage.clear();
        this.commonService.currentUser = null;
        this.router.navigate(['/auth/login'])
    }


    getCurrentUser() {
        return this.commonService.getCurrentUser();
    }

   

    checkPermission(pid) {
       
           const cu= this.getCurrentUser();
        //    console.log(cu)
        //    console.log(pid)
           if(cu.role.name === 'super Admin'){
               return true;
           }

           if(cu.role.permissions){
            return cu.role.permissions.includes(pid.toString());
           }
              return true;
    }
    

    refreshToken() {
        let appversion: any = -1;

        return this.http
            .get<any>(this.apiUrl + '/auth/refreshtoken').pipe(
                map(user => {

                    localStorage.setItem('token', user.token);
                    this.commonService.apiToken = user.token;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.commonService.setCurrentUser(user);
                    this.currentUserSubject.next(user);


                    return user;
                }));
    }

}
