import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from '../../providers/auth.service';
import { CommonService } from 'src/app/providers/common.service';

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
  pid: number;
  role:boolean;
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/",
    title: "Dashboards",
    type: "link",
    icontype: "ni-shop text-primary",
    isCollapsed: true,
    pid: 1,
    role: true
  },
  {
    path: "/customer",
    title: "Customer",
    type: "link",
    icontype: "ni-circle-08 text-green",
    pid: 2,
    role: true
  },
  {
    path: "/technician",
    title: "Technician",
    type: "link",
    icontype: "ni-archive-2 text-green",
    pid: 6,
    role: true
  },
  {
    path: "/supervisor",
    title: "Supervisor",
    type: "link",
    icontype: "ni-archive-2 text-green",
    pid: 10,
    role: true
  },
  // {
  //   path: "/system",
  //   title: "System",
  //   type: "link",
  //   icontype: "ni-archive-2 text-green",
  //   pid: 101,
  //   role: true
  // },
  {
    path: "/roles",
    title: "Roles",
    type: "link",
    icontype: "ni-single-02 text-green",
    pid: 14,
    role: true

  },

  // {
  //   path: "/equipments",
  //   title: "Equipment Details",
  //   type: "link",
  //   icontype: "ni-archive-2 text-green",
  //   pid: 2,
  //   role: true

  // },
  {
    path: "/ticket",
    title: "Ticket",
    type: "link",
    icontype: "ni-circle-08 text-green",
    pid: 15,
    role: true
  },
  // {
  //   path: "/quotation",
  //   title: "Quotation",
  //   type: "link",
  //   icontype: "ni-circle-08 text-green",
  //   pid: 101,
  //   role: true
  // },
  // {
  //   path: "/spare-parts",
  //   title: "Spare-parts",
  //   type: "link",
  //   icontype: "ni-circle-08 text-green",
  //   pid: 101,
  //   role: true
  // },
  // {
  //   path: "/invoices",
  //   title: "Invoices",
  //   type: "link",
  //   icontype: "ni-circle-08 text-green",
  //   pid: 101,
  //   role: true
  // },
  // {
  //   path: "/Report",
  //   title: "Report",
  //   type: "link",
  //   icontype: "ni-archive-2 text-green",
  //   pid: 2,
  //   role: true

  // },
  // {
  //   path: "/contracts",
  //   title: "Contracts",
  //   type: "link",
  //   icontype: "ni-archive-2 text-green",
  //   pid: 2,
  //   role: true

  // },
  // {
  //   path: "/service",
  //   title: "Services & Invoices",
  //   type: "link",
  //   icontype: "ni-archive-2 text-green",
  //   pid: 2,
  //   role: true

  // },
  {
    path: "/master",
    title: "Master",
    type: "link",
    icontype: "ni-archive-2 text-green",
    pid: 16,
    role: true
  },
  {
    path: "/service_schedule",
    title: "Service Schedule",
    type: "link",
    icontype: "ni-archive-2 text-green",
    pid: 16,
    role: true
  },
  // {
  //   path: "/promotion",
  //   title: "Promotion",
  //   type: "link",
  //   icontype: "ni-archive-2 text-green",
  //   pid: 101,
  //   role: true

  // },
  // {
  //   path: "/feedback",
  //   title: "Feedback",
  //   type: "link",
  //   icontype: "ni-archive-2 text-green",
  //   pid: 101,
  //   role: true

  // },

// {
//     path: "/",
//     title: "Customer Insights",
//     type: "sub",
//     icontype: "ni-ungroup text-orange",
//     collapse: "examples",
//     isCollapsed: true,
//     children: [
//       { path: "faq", title: "FAQ", type: "link" },
//       { path: "maintainance-checklist", title: "Maintainance-checkList", type: "link" },
//       { path: "news-letter", title: "News-Letter", type: "link" }
//     ],
//     pid: 101,
//     role: true

//   }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  public link:string;

  currentUser:any;

  constructor(
    private router: Router,
    public authService: AuthService,
    private commonService: CommonService
    ) {}

  ngOnInit() {
    this.currentUser = this.commonService.getCurrentUser();
    console.log(this.currentUser);
    // if (this.currentUser.role.isAdmin == true) {
    //   this.link = "link";
    //   this.menuItems = ROUTES.filter(menuItem => menuItem.pid == 101 || menuItem.pid == 9);
    // } else {
    //   this.link = "sub";
    //   this.menuItems = ROUTES.filter(menuItem => menuItem.pid == 2 || menuItem.pid == 9);
    // }
    this.menuItems = ROUTES;
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });
    console.log(this.menuItems, this.link);
  }
  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
}
