import { Routes } from "@angular/router";
import { PromotionComponent } from "./promotion.component";


export const PromotionRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: PromotionComponent
      }
    ]
  }
];
