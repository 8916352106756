import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { HelperService } from "src/app/providers/helper.service";
import { ApiService } from "../../providers/api.service";

export enum SelectionType {
  single = 'single',
  multi = 'multi',
  multiClick = 'multiClick',
  cell = 'cell',
  checkbox = 'checkbox',
}
@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit {

  tabs: any = [
    { name: 'New Product', name_add: 'New Product', type: 'new_product' },
    { name: 'Landmark Project', name_add: 'Landmark Project', type: 'landmark_project' },
    { name: 'Customer Test', type: 'customer_test', name_add: 'Customer Test'},
  ]
  currenttab: any = this.tabs[0];
  currenttabindex = 0;
  pagedata: any = [];
  model: any = {};

  loading: any;
  currentFilter: any = {};
  searchq: any;
  imgSize: any;
  prodctSearch: any;
  deleteModel: any = {};
  SelectionType = SelectionType
  deleteModal: BsModalRef;
  formModal: BsModalRef
  form = {
    keyboard: true,
    class: 'modal-dialog-centered modal-lg',
  }
  filedata: any;
  deleteId: any;
  delsongcat: any;
  system_types: any = [];
  ticket_types: any =[];
  constructor(
    public apiService: ApiService,
    public helperService: HelperService,
    private modalService: BsModalService,
    public toastr: ToastrService,
  ) {
    this.setActivetab(0, this.tabs[0]);
  }

  ngOnInit(): void {
    this.pageCallback({ offset: 0 });
    // this.getSystemTypeList();
    // this.getTicketTypeList();
  }

//   getSystemTypeList() {
//     this.apiService.callapi(this.apiService.APIS.MASTER_LIST,{type:'system_type'}).subscribe(res=>{
//       this.system_types = res.data;
//     })
//   }

//   getTicketTypeList() {
//     this.apiService.callapi(this.apiService.APIS.MASTER_LIST,{type:'ticket_type'}).subscribe(res=>{
//       this.ticket_types = res.data;
//     })
//   }


  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.pagedata[this.currenttabindex].offset = pageInfo.offset;
    this.getlist();
  }

  setActivetab(i, t) {
    console.log(i);
    this.currenttab = t;
    this.currenttabindex = i;
    this.pagedata[this.currenttabindex] = {};
    this.pagedata[this.currenttabindex].rows = [];
    this.pagedata[this.currenttabindex].count = 0;
    this.pagedata[this.currenttabindex].offset = 0;
    this.pagedata[this.currenttabindex].limit = 10;
    this.pagedata[this.currenttabindex].offset = 0;
    this.getlist();

  }

  search() {
    this.pageCallback({ offset: 0 });
  }

  getlist() {
    if (this.loading) { return 0; }
    this.loading = true;

    this.currentFilter.pageoffset = this.pagedata[this.currenttabindex].offset;
    this.currentFilter.limit = this.pagedata[this.currenttabindex].limit;
    if (this.searchq) {
      this.currentFilter.searchq = this.searchq;
    }
    this.currentFilter.type = this.currenttab.type;
    this.apiService.callapi(this.apiService.APIS.MASTER_ADLIST, this.currentFilter)
      .subscribe(
        res => {
          console.log('Admin list', res);

          this.pagedata[this.currenttabindex].count = res.count;
          this.pagedata[this.currenttabindex].rows = res.data;

          this.loading = false;

          if (this.currenttabindex == 3) {
            this.prodctSearch = this.pagedata[this.currenttabindex].rows;
          }

        }, error => {
          this.loading = false;
        });
  }

//   addedit() {
 
//       this.model.type = this.currenttab.type;
   
//     this.apiService.callapi(this.apiService.APIS.MASTER_ADDEDIT, this.model)
//       .subscribe(
//         res => {
//           console.log('Master  addedit', res);
//           this.loading = false;
//           this.modalService.hide(1);
//           this.helperService.showNotification('info', 'Data Uploaded Successfully');
//           this.getlist();
//         }, error => {
//           this.loading = false;
//           console.log(error);
//           this.helperService.showNotification('warning', error);
//         });
//   }

  deleteid(id) {
    console.log("delete id", id);
    this.deleteId = id;
  }
 

  openFormModal(modalForm: TemplateRef<any>) {
    this.formModal = this.modalService.show(modalForm, this.form)
  }
  hide() {
    this.formModal.hide();
  }
  openmodalDelete(modalForm: TemplateRef<any>) {
    this.deleteModal = this.modalService.show(modalForm, this.form)
  }
  close(closeModal){
    this.formModal = this.modalService.show(closeModal, this.form);
  }
  onConfirm(closeModal){
    this.formModal.hide();
    // this.closeModal.hide();
    this.modalService.hide(closeModal);
  }
}
