import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


import { HelperService } from './helper.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from '../../environments/environment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
    providedIn: 'root'
})
export class ApiService {
    apiUrl: string = environment.apiUrl;

    canSyncDBCache = true;
    flag_refreshlist = false;
    tempdata: any = {};


    metadata: any = {
        bloodgroups: [{ name: 'A+' }, { name: 'B+' }, { name: 'AB+' }, { name: 'O+' }, { name: 'A-' }, { name: 'B-' }, { name: 'AB-' }, { name: 'O-' }],
        maritalstatuses: [{ name: 'Married' }, { name: 'Single' }, { name: 'Divorced' }, { name: 'Other' }],
        genders: [{ name: 'Male' }, { name: 'Female' }, { name: 'Other' }]
    };

    DBCache: any = {};
    syncCaches = [
        { cache_key: 'DEPARTMENTS' }, { cache_key: 'BRANCHES' }
    ];
    //API Definitions
    APIS: any = {        

        MASTER_LIST: { endpoint: 'master/list', method: 'get' },
        MASTER_ADLIST: { endpoint: 'master/adlist', method: 'get' },
        MASTER_ADDEDIT: { endpoint: 'master/addedit', method: 'post' },

        ROLE_LIST: { endpoint: 'role/list', method: 'get' },
        ROLE_ADDEDIT: { endpoint: 'role/addedit', method: 'post' },
        PERMISSION_LIST: { endpoint: 'permission/list', method: 'get' },
        PERMISSION_ADDEDIT: { endpoint: 'permision/addedit', method: 'post' },

        FILE_UPLOAD: { endpoint: 'file/upload', method: 'post' },
        FILE_UPLOADFILETOSERVER: { endpoint: 'file/uploadfiletoserver', method: 'post' },


        USER_LIST: { endpoint: 'user/list', method: 'get' },
        USER_CONTACTLIST: { endpoint: 'user/contactlist', method: 'get' },
        USER_ADLIST: { endpoint: 'user/adlist', method: 'get' },
        USER_ONE: { endpoint: 'user/one', method: 'get' },
        USER_ADDEDIT: { endpoint: 'user/addedit', method: 'post' },
        USER_CUSTADDEDIT: { endpoint: 'user/customeraddedit', method: 'post' },

        TICKET_ADDEDIT: { endpoint: 'ticket/addedit', method: 'post' },
        TICKET_LIST: { endpoint: 'ticket/list', method: 'get' },
        TICKET_ADLIST: { endpoint: 'ticket/adlist', method: 'get' },
        SYSTEM_LIST: { endpoint: 'system/list', method: 'get' },
        SYSTEM_ADDEDIT: { endpoint: 'system/addedit', method: 'post' },

        SS_FILE_ADDEDIT: { endpoint: 'schedule_file/addedit', method: 'post' },
        SS_FILE_LIST: { endpoint: 'schedule_file/list', method: 'get' },
        SS_FILE_ADLIST: { endpoint: 'schedule_file/adlist', method: 'get' },

        SCHEDULE_PROCESS: { endpoint: 'schedule/importService_schedule', method: 'post' },
        SCHEDULE_ADDEDIT: { endpoint: 'schedule/addedit', method: 'post' },
        SCHEDULE__LIST: { endpoint: 'schedule/list', method: 'get' },
        SCHEDULE__ADLIST: { endpoint: 'schedule/adlist', method: 'get' },

        SAP_LIST: { endpoint: 'sap/list', method: 'get' },
        SAP_TOKEN: { endpoint: 'sap/getToken', method: 'get' },
        SAP_CREATE_TICKET: { endpoint: 'sap/createTicket', method: 'post' },
        SAP_CUSTOMER_LIST: { endpoint: 'sap/customerList', method: 'get' },
        SAP_CONTACTS: { endpoint: 'sap/getContacts', method: 'get' },
        

    }

    constructor(private http: HttpClient, public helperService: HelperService //public events: Events,
    ) { }

    callapi(api: any, params = null, CACHE_KEY = null) {
        switch (api.method) {
            case 'get':

                return this.http.get<any>(this.apiUrl + '/' + api.endpoint, { params: params }).pipe(
                    map(data => {
                        //this.helperService.storage_set('cache:'+api.endpoint,data);
                        //this.upsertDBcache(CACHE_KEY, data?.data ? data.data : data);
                        return data;
                    }));
                break;

            case 'post':
                return this.http.post<any>(this.apiUrl + '/' + api.endpoint, params).pipe(
                    map(data => {
                        return data;
                    }));
                break;
        }
    }

    // upsertDBcache(CACHE_KEY = null, data: any) {

    //     if (CACHE_KEY) {
    //         console.log('Upsert DB Cache : ' + CACHE_KEY);
    //         this.helperService.storage_set('DBCache:' + CACHE_KEY, data).then(() => {
    //             this.DBCache[CACHE_KEY] = data.data;
    //         });
    //     }
    // }

    // refreshDBcache() {
    //     console.log('Refreshing DB Cache');
    //     this.syncCaches.forEach(current => {
    //         this.helperService.storage_get('DBCache:' + current.cache_key).then(data => {
    //             this.DBCache[current.cache_key] = data.data;
    //         });
    //     });
    // }

    syncDBCache() {
        this.canSyncDBCache = false;
        console.log('Syncing DB Cache');
        this.syncCaches.forEach(current => {
            // this.callapi(this.PAGES[current.page_key].apis.list, {}, current.page_key).subscribe();
        });
    }


    uploadFile(file, reportProgress = false) {
        console.log(file);
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post<any>(this.apiUrl + '/' + this.APIS.FILE_UPLOAD.endpoint, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map(res => {
            console.log("file result", res);
            return res;
        }))
    }

    uploadFileToServer(file, reportProgress = false) {
        console.log(file);
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post<any>(this.apiUrl + '/' + this.APIS.FILE_UPLOADFILETOSERVER.endpoint, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map(res => {
            console.log("file result", res);
            return res;
        }))
    }

    uploadaudioFile(file, reportProgress = false) {
        console.log(file);
        let formData: FormData = new FormData();
        formData.append('audio', file, file.name);

        return this.http.post<any>(this.apiUrl + '/' + this.APIS.FILE_UPLOAD_AUDIO.endpoint, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map(res => {
            console.log("file result", res);
            return res;
        }))
    }

    getObjFromDBCache(CacheName: string, _id: string) {
        let object: any = {};
        // console.log(CacheName,_id)
        for (let obj of this.DBCache[CacheName]) {
            if (obj?._id == _id) {
                object = obj;
                //console.log(JSON.stringify(obj));
                break;
            }
        }
        return object;
    }

    downloadreso(Url: string) {
        return this.http.get(Url, { responseType: 'blob' });
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

  
}