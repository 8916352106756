import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HelperService } from './helper.service';
declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private searchSubject: BehaviorSubject<any>;
  public searchSubjectRes: Observable<any>;


  selectedSystem: any = {};
  apiUrl: string = environment.apiUrl;
  gpb = false;
  currentUser: any = null;
  apiToken: any = '';
  currentDevice: any = {};
  uid = '';
  loader: any = null;
  applanguage = "";
  cart: any = {};
  islogmodal: Boolean = false;
  public loadfun: boolean = false;
  //    this.cart.items=[];
  public appmode = 'customer';
  public searchqCust: any;
  constructor(private http: HttpClient, public helperService: HelperService) {
    this.searchSubject = new BehaviorSubject<any>(null);
    this.searchSubjectRes = this.searchSubject.asObservable();
  }

  searchParam(param) {
    this.loadfun = true;
    // console.log("params",param);
    this.searchqCust = param;
    this.searchSubject.next(param);

  }

  public getcurrenSearchValue() {
    return this.searchSubject.value;
  }
  randomModelName() {

    return 'model_' + Math.random();

  }

  isDevInstance() {
    if (this.apiUrl.includes('dev')) {
      return true;
    } else {
      return false;
    }
  }

  cloneWR(p) {
    return JSON.parse(JSON.stringify(p));
  }


  showModal(id: any) {
    this.openModal(id);
  }


  openModal(event) {
    document.querySelector('#' + event).classList.add('md-show');
  }

  // sa(m1,m2:any,m3:any){
  //     swal(m1,m2,m3);

  // }

  windowpopup(path = '', width = 999, height = 700) {
    window.open(path, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=50,left=200,width=' + width + ',height=' + height);
  }

  hideModal(event = '') {
    document.querySelector('#' + event).classList.remove('md-show');
    if (event === '0') {
      // jQuery(".modal").modal("hide");
    }
    else {
      //   jQuery("#"+id).modal("hide");

    }
  }


  notify(type, title, msg = null) {
    this.helperService.showNotification(type, title);
  }



  field_validate($event, cf, $object) {
    let regexp: any;

    let val: any = $event.target.value;
    if (val.length < 1) { return 0; }

    switch (cf) {

      case 'mobile':
        // regexp= /^([0-9]){10}?$/;
        regexp = /^[6-9]\d{9}$/;
        console.log(regexp.test(val));
        if (regexp.test(val)) {

        } else {
          $event.target.value = '';
          $object = '';
          this.notify('error', 'Invalid Mobile Number');
        }
        break;

      case 'pan':
        regexp = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        console.log(regexp.test(val));
        if (regexp.test(val)) {

        } else {
          $event.target.value = '';
          $object = '';
          this.notify('error', 'Invalid PAN Number');
        }
        break;

      case 'gst':
        regexp = /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z1-9]){1}([a-zA-Z0-9]){1}$/;

        if (regexp.test(val)) {

        } else {
          $event.target.value = '';
          $object = '';
          this.notify('error', 'Invalid GST Number');
        }
        break;

      case 'tan':

        regexp = /^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$/;
        if (regexp.test(val)) {

        } else {
          $event.target.value = '';
          $object = '';
          this.notify('error', 'Invalid TAN Number');
        }
        break;


    }
  }





  // getGlobalProgressbarStatus(){
  //   return this.gpb;
  // }
  // showGlobalProgressbar( ){    this.gpb=true; document.getElementById('globalprogressbar').style.display = 'block';}
  // hideGlobalProgressbar(){    this.gpb=false;document.getElementById('globalprogressbar').style.display = 'none';}

  setCurrentUser(u) {
    this.currentUser = u;
  }

  getCurrentUser() {
    if (!this.currentUser) {
      // console.log('setup cu');
      if (localStorage.getItem('currentUser')) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      }
    }
    return this.currentUser;
  }
  getCurrentRole() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }
  getCurrentRegion() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  safaridatefix(dd: any) {
    if (dd) {
      return new Date(dd.replace(/-/g, "/"));
    }
  }

  getDevice() {
    if (!this.currentDevice.firebasetoken) {
      let r: any = {};
      r.registrationId = localStorage.getItem('firebasetoken');
      r.registrationType = localStorage.getItem('tokentype');
      this.currentDevice.firebasetoken = r.registrationId;
      this.currentDevice.tokentype = r.registrationType;
    }
    return this.currentDevice;
  }

  setAppLanguage(l = 'english') {
    this.applanguage = l;
    localStorage.setItem('applanguage', l);
    //  this.translateService.setLanguage(l);
  }
  translate(key) {
    //  return this.translateService.gettranslate(key);
  }

  getAppLanguage() {
    if (!this.applanguage) {
      this.applanguage = localStorage.getItem('applanguage');
      if (!this.applanguage) {
        this.applanguage = 'english';
        this.setAppLanguage('english');
      }
    }
    return this.applanguage;
  }
  randomInt(min = 1, max = 100000) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  removeSpecialcharacters(s) {
    if (s) {
      return s.replace(/[^A-Z0-9]+/ig, "-");
    }
    return s;
  }
  removeSpecialcharactersblogs(s) {
    if (s) {
      return s.replace(/[^A-Z0-9]+/ig, "_");
    }
    return s;
  }

}
